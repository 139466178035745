import React from "react"
import { Container, Row, Col } from "react-grid-system"
import { motion } from "framer-motion"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"
const MotionDiv = motion.div

import "./ProjectCollectionPages.scss"
import { useSetRouteNavState } from "../hooks/NavState"
import { TransitionProps } from "./ITransition"
import { useWindowSize, isBreakpointMore } from "../hooks/WindowSize"
import { PrismicQuery, PrismicEdges } from "../types/prismic"
import TransitionLink from "./TransitionLink"
import SEO from "./Seo"

// define types
type PageEdge = {
  _meta: {
    uid: string
  }
  title: string
  project_collections: {
    project_collection: {
      _meta: {
        uid: string
      }
      title: string
    }
  }[]
}

type AllPages = {
  allProject_collection_pages: PrismicEdges<PageEdge>
}

interface ProjectCollectionPageProps
  extends PrismicQuery<AllPages>,
    TransitionProps {}

// define our query
export const query = graphql`
  query Project_collection_page($uid: String) {
    prismic {
      allProject_collection_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            project_collections {
              project_collection {
                ... on PRISMIC_Project_collection {
                  title
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// The inner component that will render
const ProjectCollectionPage: React.FC<ProjectCollectionPageProps> = ({
  transitionStatus,
  data,
}) => {
  // subscribe to windowSize and set the nav status
  const windowSize = useWindowSize()
  useSetRouteNavState(
    () => (isBreakpointMore("lg", windowSize) ? "open" : "closed"),
    transitionStatus,
    () =>
      isBreakpointMore("lg", windowSize)
        ? {
            to: "/",
            icon: "back",
          }
        : {
            toggle: [
              {
                icon: "open",
                state: "closed",
                to: "open",
              },
              {
                icon: "close",
                state: "open",
                to: "closed",
              },
            ],
          },
    windowSize
  )

  // get the links and ensure that we have some
  const links =
    data.prismic.allProject_collection_pages?.edges?.[0]?.node
      ?.project_collections
  if (!links || links.length === 0) return null

  const prismicData = data.prismic.allProject_collection_pages?.edges?.[0]?.node

  let animate: string
  switch (transitionStatus) {
    case "exiting":
      animate = "exit"
      break
    default:
      animate = "enter"
      break
  }
  const variants = {
    enter: (custom: number) => ({
      translateX: "0%",
      transition: {
        delay: custom * 0.1,
        ease: "linear",
      },
    }),
    exit: (custom: number) => ({
      translateY: "100%",
      transition: {
        delay: custom * 0.1,
        ease: "easeOut",
      },
    }),
    none: () => ({}),
  }
  const initial = (idx: number, animate: string) => {
    return animate === "enter" ? { translateX: `-${100 * (idx + 1)}%` } : {}
  }

  return (
    <div className="page--first-level">
      <SEO title={RichText.asText(prismicData?.title)} />
      <Container fluid className="theme-dark project-collection-page">
        <Row className="project-collection-page__item-wrap">
          {links.slice(0, 3).map((link, idx) => (
            <Col
              xl={4}
              key={`link_${link.project_collection._meta.uid}`}
              className="project-collection-item"
              component={MotionDiv}
              initial={initial(idx, animate)}
              variants={variants}
              animate={animate}
              custom={idx}
            >
              <div className="project-collection-item__bg"></div>
              <div className="project-collection-item__inner position--relative">
                <h2 className="heading--2 project-collection-item__heading">
                  <TransitionLink to={`/${link.project_collection._meta.uid}`}>
                    {RichText.asText(link.project_collection.title)}
                  </TransitionLink>
                </h2>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ProjectCollectionPage
